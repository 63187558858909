<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">注册推荐奖励|推荐人</div>
      </div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="获得积分">
              <el-input v-model="form.INTEGRAL"><template slot="append">积分奖励</template></el-input>
              
            </el-form-item>
            <!-- <div style="line-height: 30px;font-size: 14px;color: #998c85;">积分奖励</div> -->
          </el-col>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="获得余额">
              <el-input v-model="form.BALANCE"><template slot="append">元奖励</template></el-input>
            </el-form-item>
            
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="平台优惠券">
              <el-input v-model="form.CERT"></el-input>
              <!-- <el-select v-model="form.REFERENCE.CERT" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="奖励人数上限">
              <el-input v-model="form.NUMBER"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="jbtop" style="margin-top: 50px;">
        <div class="icon"></div>
        <div class="tit">基础配置|被推荐人</div>
      </div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="获得积分">
              <el-input v-model="form.BINTEGRAL"><template slot="append">积分奖励</template></el-input>

            </el-form-item>
           
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="display: flex;">
            <el-form-item label="获得余额">
              <el-input v-model="form.BBALANCE"><template slot="append">元奖励</template></el-input>
            </el-form-item>
            
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="平台优惠券">
              <el-input v-model="form.BCERT"></el-input>
              <!-- <el-select v-model="form.BREFERENCE.CERT" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div class="butt">
        <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
        <el-button size="medium" type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
      form: {
        INTEGRAL: '',
        BALANCE: '',
        CERT: '',
        NUMBER: '',
        BINTEGRAL: '',
        BBALANCE: '',
        BCERT: '',

      },
      tableData: [
        { name: 'wdz' }
      ],
      showDialog: false,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],

    };
  },
  mounted() {
    var that = this;
    that.$http.post("/setup/queryOne", {
      id: 10,
      setupType: 6,
    }).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.form = response.data.data.setupContentJson



      }
    });
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response.data.url);
    },
    onSubmit() {
      var that = this;
      that.$http.post("/setup/update", {
        id: 10,
        setupType: 6,
        setupContent: that.form
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message({
            type: 'success',
            message: '配置成功'
          });

        }
      });
      // this.$router.push('/staff')
    },
    add() {
      this.showDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;

  .jbxx {
    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin: 0 10px;
      margin-bottom: 40px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }


    }

    .edi {
      margin-left: 20px;
      height: 450px;
      display: flex;

      .editor {
        width: 817px;
        height: 372px;
        margin-top: 20px;
      }
    }
  }

  .butt {
    display: flex;
    justify-content: space-between;
    padding: 50px 700px;
  }

  .tab {
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}
</style>